<template>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-9 col-md-9 col-12">
                    <div class="logo-header">
                        <img class="logo" alt="Demp Vianen B.V. logo" src="./assets/img/logo-light.svg">
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <h1>Demp BV</h1>
                <ul>
                    <li>Pascalstraat 12 A</li>
                    <li>3771 RT Barneveld</li>
                    <li><a href="mailto:info@demp-vianen.nl">info@demp-vianen.nl</a></li>
                    <li>Tel <a href="tel:0031850812037">+31 85 081 20 37</a></li>
                </ul>
                <a class="btn mx-auto" href="mailto:info@demp-vianen.nl">Stuur e-mail</a>
            </div>
        </div>
    </section>
</template>
